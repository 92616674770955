/* eslint-disable vue/no-parsing-error */
<template>
  <div class="frm-wrap">
    <modal class="modalUpload" name="upload">
      <form class="m-4" @submit.prevent="loadExploreData">
        <div class="mb-3">
          <label for="slectedJson" class="form-label">Upload data(JSON)</label>
          <input
            class="form-control form-control-sm"
            id="slectedJson"
            type="file"
          />
        </div>
        <button class="btn btn-primary" type="submit">Load</button>
      </form>
    </modal>
    <modal class="modalUpload" name="uploadQuest">
      <form class="m-4" @submit.prevent="loadQuestData">
        <div class="mb-3">
          <label for="slectedQuestJson" class="form-label"
            >Upload data(JSON)</label
          >
          <input
            class="form-control form-control-sm"
            id="slectedQuestJson"
            type="file"
          />
        </div>
        <button class="btn btn-primary" type="submit">Load</button>
      </form>
    </modal>
    <div class="d-flex justify-content-between">
      <h4 v-if="this.$store.state.gmodule.groupMode">Group Operations</h4>
      <h4 v-else>Node Operations</h4>
      <div class="form-check form-switch">
        <input
          role="button"
          class="form-check-input"
          type="checkbox"
          v-model="groupMode"
          id="flexSwitchCheckChecked"
          checked
        />
      </div>
    </div>
    <div v-if="this.$store.state.gmodule.groupMode" class="group-operation">
      <div class="group-operation__icon--box">
        <i
          title="Delete items"
          class="bx bx-trash group-operation__icon box-icon box-icon--effect"
          @click="deleteSelectedNodes"
          name="trash"
          v-if="abilities.can('graph', 'ComboCreation')"
        >
        </i>

        <i
          title="Create combo"
          class="bx bx-message-rounded-add group-operation__icon box-icon box-icon--effect"
          :class="getActiveGroup('combo') ? 'box-icon--active' : ''"
          @click="addComboEvent"
          name="message-rounded-add"
          v-if="abilities.can('graph', 'ComboCreation')"
        >
        </i>

        <i
          title="Create node"
          class="bx bx-message-square-add group-operation__icon box-icon box-icon--effect"
          :class="getActiveGroup('node') ? 'box-icon--active' : ''"
          @click="addNodeEvent"
          name="message-square-add"
          v-if="abilities.can('graph', 'CreateNode')"
        >
        </i>

        <i
          title="Write Query"
          class="bx bx-git-branch group-operation__icon box-icon box-icon--effect"
          :class="getActiveGroup('query') ? 'box-icon--active' : ''"
          @click="addQueryEvent"
          v-if="abilities.can('graph', 'SmartQuery')"
        >
        </i>

        <i
          title="Add hull"
          class="bx bx-palette group-operation__icon box-icon box-icon--effect"
          :class="getActiveGroup('hull') ? 'box-icon--active' : ''"
          @click="addHull"
        >
        </i>
        <i
          title="Group Node Analysis using OpenAI"
          class="bx bx-pyramid group-operation__icon box-icon box-icon--effect"
          :class="getActiveGroup('hull') ? 'box-icon--active' : ''"
          @click="processSelectedNodesThroughOpenAI"
        >
        </i>
         <i
        class="bx bx-brain group-operation__icon box-icon box-icon--effect"
        title="Logical Search (alt+shift+L)"
        @click="toggleLogicalSearch"
      ></i>
      </div>
      <div v-if="toggleGroupForm" class="group-operation__form">
        <form @submit.prevent="sendCreation">
          <!-- <input v-if="(groupObj.type !== 'query') && (groupObj.type !== 'hull')"
            type="text"
            class="input-wrap"
            :placeholder="groupObj.label"
            v-model="currentLabel"
          /> -->
          <ed-input
            v-if="groupObj.type !== 'query' && groupObj.type !== 'hull'"
            class="my-3"
            :onChange="setCurrentLabel"
            :placeholder="groupObj.label"
            size="large"
          />
          <color-picker
            class="my-3"
            v-if="groupObj.type === 'hull'"
            v-model="colors"
          />
          <!-- Textarea for query -->
          <vue-tribute :options="tributeOptions">
            <textarea
              v-if="groupObj.type === 'query'"
              class="form-control mb-3"
              :placeholder="groupObj.label"
              rows="9"
              spellcheck="false"
              v-model="currentQuery"
            ></textarea>
          </vue-tribute>
          <!-- Only for node -->
          <!-- <input
            v-if="groupObj.isRelationShip"
            type="text"
            class="input-wrap"
            placeholder="Enter name of relationship"
            v-model="currentEdgeLabel"
          /> -->
          <ed-input
            v-if="groupObj.isRelationShip"
            class="my-3"
            :onChange="setcurrentEdgeLabel"
            placeholder="Enter name of relationship"
            size="large"
          />
          <button type="submit" class="ed-btn__blim common-btn">
            {{ groupOperationButton }}
          </button>
          <span class="divider" v-if="groupObj.type === 'query'">or</span>
          <div
            class="d-flex flex-column align-items-center"
            v-if="groupObj.type === 'query'"
          >
            <div class="d-flex align-items-center mb-2">
              <span class="ed-label">Upload Data using CSV </span>
              <a
                href="https://tj-dictionary.netlify.app/av-help.csv"
                class="help-icon box-icon"
                ><i
                  class="bx bx-help-circle"
                  title="Click here to download CSV template"
                  download
                ></i
              ></a>
            </div>
            <input
              type="file"
              accept=".csv"
              @change="changeCsvData"
              ref="mycsvupload"
              hidden
            />
            <div class="d-flex align-items-center flex-wrap">
              <div
                v-if="uploadedCSV.isExist"
                class="ed-btn__blim"
                @click="uploadData"
                title="Upload CSV"
              >
                Upload
                <i class="bx bx-upload"></i>
              </div>
              <div
                v-else
                class="ed-btn__blim"
                @click="uploadCSV"
                title="Select the file to upload"
              >
                Choose CSV File
                <i class="bx bx-file"></i>
              </div>
              <i
                v-if="uploadedCSV.isExist"
                @click="cancelCSVUpload"
                title="Cancel"
                class="mx-2 bx bx-x box-icon box-icon__md box-icon__round"
              ></i>
            </div>
            <span class="mx-2 mt-2">{{ uploadedCSV.title }}</span>
            <div
              class="d-flex align-items-center justify-content-right mt-2"
            ></div>
          </div>
        </form>
      </div>
      <div class="mt-2">
        <div class="alert alert-warning" role="alert">
          <strong>Warning:</strong> Group operation is in development mode right
          now.
        </div>

        <!-- Temporary Item -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Important Note :-
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  use <span class="badge bg-secondary">shift + mouse</span> to
                  activate selection tool
                </li>
                <br />
                <li>
                  you can also select mutliple nodes with
                  <span class="badge bg-secondary">`Ctrl`</span>(Only works in
                  defualt mode)
                </li>
                <br />
                <li>
                  Creating combo is not working as expected. So, once you've
                  created new combo after selecting multiple nodes, then you
                  will have to drag at least one of the nodes inside the new
                  combo.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="this.$store.state.gmodule.groupMode === false"
      class="group-operation"
    >
      <div class="controller-box">
        <div class="controller" v-for="item in inputRadioList" :key="item.id">
          <input
            type="radio"
            :id="item.id"
            :value="item.id"
            class="controller__input-button"
            :checked="item.isSelected"
            v-model="picked"
            role="button"
          />
          <i
            :class="`${
              item.isSelected
                ? 'controller__icon controller__icon--active'
                : 'controller__icon'
            } bx bx-${item.icon}`"
            :title="getShortcut(item.label, item.id)"
            @click="clickedEvent(item.id)"
          ></i>
          <!-- <label :for="item.id">{{ item.label }}</label> -->
        </div>
         <div class="controller">

        <i
         class="controller__icon controller__icon bx bx-anchor"
        title="Thought Marker (alt+shift+M)"
        @click="updateAnchor"
      ></i>
        </div>
        <div class="controller">
          <i
            :class="`${
              graphConfigs
                ? 'controller__icon controller__icon--active'
                : 'controller__icon'
            } bx bx-doughnut-chart`"
            title="Graph Configs"
            @click="toggleGraphConfigs"
          ></i>
        </div>

        <!-- <div class="controller">
          <i
            :class="`${
              questMode
                ? 'controller__icon controller__icon--active'
                : 'controller__icon'
            } bx bx-question-mark`"
            title="Quest Mode"
            @click="toggleQuestMode"
          ></i>
        </div> -->
      </div>
      <hr />
      <div class="group-operation__icon--box">
        <i
          name="anchor"
          @click="toggleEdgeMap"
          title="See Relations"
          :class="edgeMap ? 'box-icon--active' : ''"
          class="box-icon box-icon--effect bx bx-broadcast"
        ></i>
        <i
          name="right-arrow-alt"
          @click="toggleArrow"
          title="Add arrow"
          :class="arrowEnabled ? 'box-icon--active' : ''"
          class="box-icon box-icon--effect bx bx-right-arrow-alt"
        ></i>
      </div>

      <div class="mt-3">
        <label for="nodeSize" class="form-label ed-label"
          >Set search depth</label
        >
        {{ getCurrentRange }}
        <input
          type="range"
          min="0"
          max="100"
          v-model="rangeData"
          class="form-range ed-slider"
          id="nodeSize"
        />
      </div>
      <div class="mt-3" v-if="isVrAvailable">
        <label for="nodeSize" class="form-label ed-label"
          >VR Graph Scale {{ isVrAvailable? '': '( VR not Detected )' }}</label
        >
        {{ vrGraphScale }}
        <input
          type="range"
          min="1"
          max="5"
          v-model="vrGraphScale"
          class="form-range ed-slider"
          id="nodeSize"
        />
      </div>
      <div class="mb-3" v-show="graphConfigs">
        <GraphConfig />
      </div>

      <!-- Condition based elements -->
      <div v-if="this.$store.state.gmodule.edit_mode === 'add_nodes'">
        <div class="add-node__label">
          <span class="search_wrap">Node Label</span>
          <ed-input
            :onChange="setNodeLabelName"
            placeholder="Label name of your Node"
            size="large"
          />
        </div>
        <div class="add-node__type">
          <span class="search_wrap">Node Type</span>
          <select v-model="newNodeType">
            <option value="custom-node">Custom</option>
            <option value="openai">OpenAI</option>
            <option value="function">Function</option>
          </select>
        </div>
      </div>

      <div
        v-else-if="this.$store.state.gmodule.edit_mode === 'add_relationships'"
      >
        <span class="search_wrap">Edge Label</span>
        <ed-input
          :onChange="setEdgeLabel"
          placeholder="Label of your Edge"
          size="large"
        />
      </div>

      <div v-else>
        <div class="mb-3">
          <span class="search_wrap ed-label">Save As</span>
          <edInput
            :onChange="setGraphName"
            placeholder="Name of the Graph"
            required=""
          />
        </div>
        <div class="mb-3">
          <label class="ed-label">Choose a Category</label>
          <ed-dropdown
            :selected="selectedCategories"
            :listItems="categories"
            :isSelected="selectCategory"
          />
        </div>
        <div class="graph-lib__input-form">
          <label class="ed-label">Select Audience</label>
          <ed-dropdown
            :listItems="audience"
            :selected="graphAccess"
            :isSelected="setGraphAccess"
          />
        </div>
        <div class="graph-lib my-3" v-if="graphAccess === 'custom'">
          <label class="ed-label">Add collabrators</label>

          <multiselect
            v-model="sharedAccess"
            @search-change="updateSharedOptions"
            :multiple="true"
            :options="usersList"
            label="username"
            track-by="username"
          ></multiselect>
        </div>
        <div class="mb-3">
          <span class="ed-label">Tags</span>
          <edInput
            :onChange="setTags"
            placeholder="e.g. Music, Sitar, Entertainment"
          />
        </div>
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <button
            :class="
              isLoading ? 'ed-btn__blim ed-btn__blim--disabled' : 'ed-btn__blim'
            "
            @click="saveMyGraph"
            :disabled="isLoading"
          >
            Save Graph
          </button>
          <div
            class="spinner-border spinner-border ml-2"
            :style="{ opacity: isLoading ? 1 : 0 }"
          ></div>
        </div>
        <!-- Error -->
        <div class="mb-3">
          <span
            :class="
              savedGraph.isError ? 'ed-label--danger' : 'ed-label--success'
            "
            v-if="savedGraph.showLabel"
            >{{ savedGraph.label }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTribute from 'vue-tribute';
import graphParse from '@/common/core/Graph/graphParse';
import GraphConfig from '@/common/widgets/GraphControlsWidget/GraphConfig.vue';
import { uniqueIdGenerate } from '@/utils/reuse';
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
import AbilityMixin from '@/mixins/AbilityMixin';
import configs from '@/config/graphControls';
import graphReactor from '@/common/core/Graph/graphReactor';
import graphFunc from '@/common/core/Graph/query/queryGenerator';
import smartQueryMode from '@/common/core/Graph/query/smartQueryMode';
import edInput from '@/common/components/ed-forms/ed-input.vue';
import edDropdown from '@/common/components/ed-forms/ed-dropdown.vue';
import mapExploreGraphToNodaVr from '@/utils/mapExploreGraphToNodaVr';

export default {
  components: {
    edInput,
    edDropdown,
    GraphConfig,
    VueTribute,
  },
  mixins: [AbilityMixin],

  data() {
    return {
      tags: '',
      sharedAccess: null,
      selectedCategories: 'Uncategorized',
      graphAccess: 'public',
      audience: ['public', 'private', 'custom'],
      file: null,
      categories: configs.categories,
      edgeMap: false,
      groupMode: false,
      colors: '',
      picked: 'looked_at',
      graph_name: '',
      rangeData: 0,
      vrScale: this.$store.state.gmodule.vrScale,
      edgeStrength: 0,
      nodeLabelName: '',
      newNodeType: 'custom',
      edgeLabelName: '',
      queryMode: 'normal',
      currentQuery: '',
      finalQuery: '',
      currentLabel: '',
      currentEdgeLabel: '',
      groupObj: { type: 'none', label: 'node' },
      toggleGroupForm: false,
      activeTab: 'edge-strength',
      inputRadioList: configs.inputRadioList,
      graphConfigs: false,
      isLoading: false,
      savedGraph: {
        isError: false,
        showLabel: false,
        label: 'Graph not Saved',
      },
      uploadedCSV: {
        title: '',
        isExist: false,
      },
      tributeOptions: {
        trigger: ':',
        values: [
          {
            key: 'initCreate',
            value: 'mode:smart\n\ncreate(\nRam | eats | Mangoes.\n)',
          },
          {
            key: 'initFetch',
            value: 'mode:smart\n\nfetch(\nAmitabh Bachchan\n)',
          },
          {
            key: 'create',
            value: 'create(\n\n)',
          },
          {
            key: 'fetch',
            value: 'fetch(\n\n)',
          },
          {
            key: 'mode:smart',
            value: 'mode:smart',
          },
        ],
        // element to target for @mentions
        iframe: null,
        // class added in the flyout menu for active item
        selectClass: 'highlight',
        // class added to the menu container
        containerClass: 'tribute-container',
        // function called on select that returns the content to insert
        selectTemplate(item) {
          return `${item.original.value}`;
        },
        noMatchTemplate() {
          return '<span style:"visibility: hidden;"></span>';
        },
        autocompleteMode: true,
        menuShowMinLength: 3,
      },
    };
  },
  computed: {
    usersList() {
      return this.$store.state.gmodule.usersList;
    },
    stateEdgeMap() {
      return this.$store.state.isEdgeMap;
    },
    arrowEnabled() {
      return this.$store.state.gmodule.isArrowEdge;
    },
    getUploadingStatus() {
      return this.$store.state.is_uploading_data;
    },
    isQuestUploading() {
      return this.$store.state.isQuestUploading;
    },
    getCurrentRange() {
      return this.$store.state.explore_data_range;
    },
    getEdgeStrength() {
      return this.$store.state.edge_strength;
    },
    getStateMode() {
      return this.$store.state.gmodule.edit_mode;
    },
    getStateGroupMode() {
      return this.$store.state.gmodule.groupMode;
    },
    filteredInputRadioList() {
      return this.inputRadioList.filter((item) => this.abilities.can('graph', item.linkedAbility));
    },
    // questMode() {
    //   return this.$store.state.quests.quest_mode;
    // },
    groupOperationButton() {
      const choice = this.groupObj.type || 'query';
      switch (choice) {
        case 'combo':
          return 'Create Combo';
        case 'query':
          return 'Run Query';
        case 'node':
          return 'Create Node';
        default:
          return 'Submit';
      }
    },
    vrGraphScale: {
      get() {
        return this.$store.state.gmodule.vr.scale;
      },
      async set(val) {
        await window.noda.clearMap();
        this.$store.dispatch('gmodule/setVrScale', { scale: parseInt(val, 0) });
        const nodaGraph = mapExploreGraphToNodaVr(this.$store.state.gmodule.g6_explore_data, parseInt(val, 0));
        console.log('xvf', nodaGraph);
        await window.noda.loadJson({ jsonString: nodaGraph });
      },
    },
    isVrAvailable() {
      if (!window.noda.isInstalled()) return false;
      return true;
    },
  },

  mounted() {
    this.rangeData = this.getCurrentRange;
    this.edgeStrength = this.getEdgeStrength;
    this.edgeMap = this.stateEdgeMap;
    window.addEventListener('keydown', this.handleKeyPress);
  },

  watch: {
    edgeMap(newVal) {
      this.$store.dispatch('setEdgeValue', newVal);
    },
    getUploadingStatus() {
      this.$modal.show('upload');
    },
    isQuestUploading() {
      this.$modal.show('uploadQuest');
    },
    groupMode(val) {
      this.$store.dispatch('gmodule/toggleGroupMode', val);
    },
    getStateGroupMode(val) {
      this.groupMode = val;
    },
    getStateMode() {
      this.picked = this.getStateMode;
    },
    rangeData(val) {
      this.$store.dispatch('setRangeValue', val);
    },
    edgeStrength(val) {
      this.$store.dispatch('setEdgeStrength', val);
    },
    picked(newVal) {
      this.clickedEvent(newVal);
      this.$store.dispatch('gmodule/setEditMode', newVal);
    },
    nodeLabelName(val) {
      this.$store.dispatch('setNodeLabel', val);
    },
    newNodeType(val) {
      this.$store.dispatch('setNewNodeType', val);
    },
    edgeLabelName(val) {
      this.$store.dispatch('setEdgeLabel', val);
    },
    // vrGraphScale(val) {
    //   console.log('xvf', 'vrScale', val);
    //   this.$store.dispatch('gmodule/setVrScale', { scale: val });
    // },
  },

  methods: {
    updateAnchor() {
      this.$store.dispatch('gmodule/updateAnchor');
    },
    updateSharedOptions(val) {
      this.$store.dispatch('gmodule/userListSearchUpdate', val);
    },
    toggleArrow() {
      this.$store.dispatch('gmodule/toggleArrow');
    },
    getActiveGroup(type) {
      return type === this.groupObj.type;
    },
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },

    showError(response) {
      this.savedGraph.showLabel = true;
      if (response) {
        this.savedGraph.label = 'Graph saved';
        this.graph_name = '';
        this.tags = '';
        this.selectedCategories = 'Uncategorized';
      } else {
        this.savedGraph.isError = true;
        this.savedGraph.label = 'Please Enter The Graph Name';
      }
      setTimeout(() => {
        this.savedGraph.showLabel = false;
        this.savedGraph.isError = false;
      }, 2000);
    },

    changeCsvData(e) {
      // eslint-disable-next-line prefer-destructuring
      this.file = e.target.files[0];

      this.uploadedCSV.title = this.file.name;
      this.uploadedCSV.isExist = true;
    },

    async uploadData() {
      const fr = new FileReader();
      fr.onload = async (e) => {
        // eslint-disable-next-line new-cap
        const parsedGraphData = new graphParse(e.target.result).parse();

        if (parsedGraphData.type === 'nodeEdgeList') {
          this.$store.dispatch('gmodule/csvDataExpand', parsedGraphData.data);
        } else if (parsedGraphData.type === 'comboPropList') {
          this.$store.dispatch(
            'gmodule/comboPropOperation',
            parsedGraphData.data,
          );
        }
      };
      fr.addEventListener('loadend', this.handleReaderEvents);
      fr.addEventListener('progress', this.handleReaderEvents);
      fr.addEventListener('error', this.handleReaderEvents);
      fr.readAsText(this.file);
    },
    cancelCSVUpload() {
      this.$refs.mycsvupload.value = null;
      this.uploadedCSV.title = '';
      this.uploadedCSV.isExist = false;
    },
    /* Event handler for closing after File Read */
    async handleReaderEvents(event) {
      if (event.type === 'loadend') {
        this.$refs.mycsvupload.value = null;
        this.uploadedCSV.title = '';
        this.uploadedCSV.isExist = false;
      }

      /* Event handler on loading */
      if (event.type === 'progress') {
        this.uploadedCSV.title = 'Please Wait while Process';
      }

      /* Event handler on error */
      if (event.type === 'error') {
        this.$refs.mycsvupload.value = null;
        this.uploadedCSV.title = 'Something is missing!';
        this.uploadedCSV.isExist = false;
        setTimeout(() => {
          this.uploadedCSV.title = 'Please Select A File First';
        }, 2000);
      }
    },
    loadExploreData() {
      const { files } = document.getElementById('slectedJson');
      const fr = new FileReader();
      fr.onload = (e) => {
        const result = JSON.parse(e.target.result);

        this.$store.dispatch('gmodule/setExploreData', result);
      };
      fr.readAsText(files[0]);
      this.$modal.hide('upload');
    },
    /** Just to traverse uploaded quest_data to add label and tjType Field
     * populateField: (It adds label and tjType in each branch)
     */
    populateField(node) {
      const nodeCfg = node;
      nodeCfg.label = nodeCfg.id;
      nodeCfg.tjType = 'openai_node';
    },
    // Simple traversal
    traverseBranch(tempData) {
      this.populateField(tempData);
      if (tempData.children) {
        tempData.children.forEach((i) => {
          this.traverseBranch(i);
        });
      }
    },

    loadQuestData() {
      const { files } = document.getElementById('slectedQuestJson');
      const fr = new FileReader();
      fr.onload = (e) => {
        const result = JSON.parse(e.target.result);
        this.traverseBranch(result);
        this.$store.dispatch('quests/setQuestGraphData', result);
      };
      fr.readAsText(files[0]);
      this.$modal.hide('uploadQuest');
    },

    async saveMyGraph() {
      try {
        let payloadObj;
        this.toggleLoading();
        if (this.$store.state.library_mode_quest) {
          payloadObj = {
            graph_name: this.$store.state.quest_graph_data.label,
            gdata: this.$store.state.quest_graph_data,
            graph_type: [this.selectedCategories],
            graph_tags: this.tags,
            graph_access: this.graphAccess,
            graph_dimension: 'quest_library',
            ...(this.graphAccess === 'custom' && {
              sharedAccess: this.sharedAccess.map((i) => i.id),
            }),
          };
        } else {
          payloadObj = {
            graph_name: this.graph_name,
            gdata: graphReactor.exploreInstance.save(),
            graph_type: [this.selectedCategories],
            graph_tags: this.tags,
            graph_access: this.graphAccess,
            graph_dimension: 'explore_library',
            ...(this.graphAccess === 'custom' && {
              sharedAccess: this.sharedAccess.map((i) => i.id),
            }),
          };
        }

        const savedGraph = await this.$store.dispatch(
          'saveCurrentGraph',
          payloadObj,
        );

        if (savedGraph) {
          // delete data before sending it to socket
          const socketPayload = {
            // eslint-disable-next-line no-underscore-dangle
            _id: savedGraph._id,
            id: savedGraph.id,
            g6_data: {
              graph_name: savedGraph.g6_data.graph_name,
              graph_type: savedGraph.g6_data.graph_type,
            },
            timestamp: savedGraph.timestamp,
            actor: savedGraph.actor,
            tags: savedGraph.tags,
            access: savedGraph.access,
            sharedAccess: savedGraph.sharedAccess,
          };
          this.$socket.emit('create_graph', socketPayload);
        }
        this.showError(savedGraph);
        this.toggleLoading();
      } catch (error) {
        this.showError(false);
        this.toggleLoading();
      }
    },

    sendCreation() {
      if (this.groupObj.type === 'combo') {
        this.$root.$emit('combo-create-select', this.currentLabel);
        this.toggleGroupForm = false;
      } else if (this.groupObj.type === 'node') {
        const payload = {
          currentLabel: this.currentLabel,
          currentEdgeLabel: this.currentEdgeLabel,
        };
        this.$root.$emit('node-create-select', payload);

        this.toggleGroupForm = false;
      } else if (this.groupObj.type === 'query') {
        this.queryOperation();
      } else if (this.groupObj.type === 'hull') {
        this.creatHullOperation();
      }
    },

    creatHullOperation() {
      const nodes = graphReactor.exploreInstance.findAllByState(
        'node',
        'selected',
      );

      const id = uniqueIdGenerate('hull');

      const hull = graphReactor.exploreInstance.createHull({
        id,
        type: 'bubble',
        members: nodes,
        padding: 10,
        style: {
          fill: this.colors.hex8,
          stroke: 'green',
        },
      });
      // eslint-disable-next-line
      graphReactor.exploreInstance.on("afterupdateitem", (e) => {
        hull.updateData(hull.members);
      });
    },

    deleteSelectedNodes() {
      this.toggleGroupForm = false;
      this.$root.$emit('delete-selected-nodes');
    },

    processSelectedNodesThroughOpenAI() {
      this.$root.$emit('process-selected-nodes-openai');
    },

    queryOperation() {
      // Check whether query starts with mode: keyword if yes then assign it to queryMode
      if (this.currentQuery.startsWith('mode:')) {
        const replaceWord = this.currentQuery.match(/mode:\w+/g)[0];
        // eslint-disable-next-line prefer-destructuring
        this.queryMode = replaceWord.split(':')[1];

        this.finalQuery = this.currentQuery.replace(replaceWord, '');
      } else {
        this.queryMode = 'normal';
        this.finalQuery = this.currentQuery;
      }
      const edEmbed = `$ed ${this.finalQuery} ed$`; // embedding $ed to distinguish from other queries
      const sanitizedQuery = graphFunc.filterQuery(edEmbed); // sanitize query: it will break this into an array
      const queryObj = {
        type: this.queryMode, // mode
        query: sanitizedQuery, // query
      };

      const resultPayload = graphFunc.filterResult(queryObj);

      if (this.queryMode === 'normal') {
        exploreOps.queryOperation(resultPayload);
      } else if (this.queryMode === 'smart') {
        const globalOperations = smartQueryMode(this.finalQuery);
        if (globalOperations.createList !== undefined) {
          exploreOps.queryOperation(globalOperations.createList);
        } else if (globalOperations.fetchList !== undefined) {
          globalOperations.fetchList.forEach(async (query) => {
            await this.$store.dispatch(
              'gmodule/exploreGraphFetch',
              query.trim(),
            );
          });
        }
      }
      this.$store.dispatch('gmodule/saveG6ExploreData');
    },

    addComboEvent() {
      this.toggleGroupForm = true;
      this.groupObj = {
        type: 'combo',
        label: 'Enter name of combo',
      };
    },

    addQueryEvent() {
      this.toggleGroupForm = true;
      this.groupObj = {
        type: 'query',
        label: 'Enter your query',
      };
    },

    addHull() {
      this.toggleGroupForm = true;
      this.groupObj = {
        type: 'hull',
        label: 'Enter name of hull',
      };
    },

    addNodeEvent() {
      this.toggleGroupForm = true;
      this.groupObj = {
        type: 'node',
        label: 'Enter name of node',
        isRelationShip: true,
      };
    },

    activateTab(name) {
      this.activeTab = name;
    },

    activeClassName(name) {
      return { active: this.activeTab === name };
    },

    activeTabClass(name) {
      return { show: this.active === name, active: this.active === name };
    },

    clickedEvent(id) {
      // eslint-disable-next-line
      const newControllers = this.inputRadioList.map((control) => {
        const temp = control;
        if (temp.id === id) {
          this.picked = id;
          temp.isSelected = true;
        } else {
          temp.isSelected = false;
        }
        return temp;
      });
      this.inputRadioList = newControllers;
    },
    selectCategory(tab) {
      this.selectedCategories = tab;
    },
    setGraphName(val) {
      this.graph_name = val;
    },
    setGraphAccess(val) {
      this.graphAccess = val;
    },
    setTags(val) {
      this.tags = val;
    },
    setCurrentLabel(val) {
      this.currentLabel = val;
    },
    setcurrentEdgeLabel(val) {
      this.currentEdgeLabel = val;
    },
    setSelectedGraphConfig(tab) {
      this.selectedGraphConfig = tab;
      this.graphConfigList = this.graphConfigList.map((each) => {
        const myTab = each;
        if (myTab.label === tab) {
          if (myTab.isSelected === true) {
            myTab.isSelected = false;
            this.selectedGraphConfig = '';
          } else {
            myTab.isSelected = true;
          }
        } else {
          myTab.isSelected = false;
        }
        return myTab;
      });
    },
    setNodeLabelName(value) {
      this.nodeLabelName = value;
    },
    setEdgeLabel(value) {
      this.edgeLabelName = value;
    },
    toggleEdgeMap() {
      this.edgeMap = !this.edgeMap;
    },
    toggleGraphConfigs() {
      this.graphConfigs = !this.graphConfigs;
    },
    handleKeyPress(event) {
      if (event.altKey && event.shiftKey && event.code === 'KeyV') {
        event.preventDefault();
        this.toggleGraphConfigs();
      }
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
    toggleLogicalSearch() {
      this.$root.$emit('logicalSearch');
    },
    // toggleQuestMode() {
    //   this.$store.dispatch('quests/setQuestMode', !this.questMode);
    // },
    getShortcut(label, _key) {
      return this.$fetchShortcut(label, _key);
    },
    uploadCSV() {
      this.$refs.mycsvupload.click();
    },
    // fetchShortcut(_key) {
    //   const val = universalMap.filter((eachMap) => eachMap.mode_name === _key);
    //   if (val.length > 0) {
    //     return val[0].hotKey;
    //   }
    //   return '';
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/components/ed-slider";

.modalUpload {
  color: black;
}

.select-cat {
  width: auto;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  padding: 0.5em;
  border-radius: 0.3125em;
  transition: all ease-in-out 200ms;

  &:hover {
    background-color: $edvantaButtonColor;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.slider-div {
  background-color: $lightGray;
  padding: 0.625em;

  .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    @include custom-scroll-bar;

    .nav-link {
      white-space: nowrap;
      color: $edvantaBlue;
    }
  }
}
.rd_content {
  margin-right: 0.75em;
}
.frm-wrap {
  padding: 0.5em 0.1875em;
  text-transform: capitalize;
  width: 100%;
}
span.search_wrap {
  display: inline-block;
  margin-right: 1.5em;
}
input.text_bar {
  border-radius: 0.3rem;
  border: none;
  border: 0.0625em solid gray;
  width: 100%;
}
button.btn_bar {
  margin-top: 1em;
  border-radius: 0.25em;
  background-color: $edvantaBlue;
  color: #fff;
  margin-right: 0.625em;
}
.controller-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.controller {
  display: flex;
  margin: 0.3rem;
  cursor: pointer;

  &__input-button {
    display: none;
  }

  &__icon {
    fill: $color-grey-1;
    color: $color-grey-1;
    // padding: 0.1rem;
    transition: all 0.3s ease-in;
    border-radius: 1rem;
    font-size: 1.5rem;

    &:hover {
      fill: $secondaryBlue;
      color: $secondaryBlue;
      background: rgba($color-grey-2, 0.5);
    }

    &--active,
    &--active:hover {
      fill: $edvantaBlue;
      color: $edvantaBlue;
      // background: $secondaryBlue;
      // background: rgb(0, 132, 255);
    }
  }
}

.max-wrap {
  width: 100%;
}
.divider {
  margin: 1.4rem auto 0.5rem;
  display: block;
  text-align: center;
}

.common-btn {
  transform: translateX(-50%);
  width: 80%;
  margin-left: 50%;
}

.help-icon {
  display: inline;
  margin-top: 0.13rem;
  margin-left: 0.5rem;
}

.tribute-container {
  list-style: none;
}
</style>
