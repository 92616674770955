/* eslint-disable */
import Papa from 'papaparse';
import { nodePlate } from '@/common/core/Graph/plate';

const papaParseConfig = {
  delimiter: '',
  newline: '',
  quoteChar: '"',
  header: true,
  escapeChar: '"',
};

class GraphParse {
  // assign data to constructor
  constructor(data) {
    this.data = this.papaParse(data).data;
    this.reserveHeads = ['nodeid', 'color', 'fetch', 'combo', 'description'];
    this.headerCheck(this.data);
  }
  papaParse(data) {
    const csvData = Papa.parse(data, papaParseConfig);
    return csvData;
  }
  actorVerbObjSanitize() {
    const data = this.data.filter(item => {
      return Object.hasOwn(item, 'actor') && Object.hasOwn(item, 'verb') && Object.hasOwn(item, 'object')
    })
    return data;
  }

  headerCheck(data) {
    const checkList = ['actor', 'verb', 'object'];
    // if => dataHeader has actor, verb and object properties
    // ? dataHeader
    const dataHeader = Object.keys(data[0]).map((i) => i.toLowerCase());
    // ? For Csv data
    const csvDataHeader = dataHeader.filter((i) => checkList.includes(i));

    if (csvDataHeader.length === 3) {
      // Node Edge Parsing CSV
      // parseOperation - nodeEdgeParsing
      this.data = this.actorVerbObjSanitize()
      this.operation = this.nodeEdgeParsing;
    } else if (dataHeader.includes('nodeid')) {
      // Combo Parsing CSV
      // parseOperation - comboPropParsing

      this.operation = this.comboPropParsing;
    } else {
      this.operation = this.invalidData;
    }
  }

  /** Parse Function */

  parse() {
    return this.operation();
  }
  /**
   * NodeEdge Parsing Function and helper functions
   */

  nodeEdgeParsing() {
    return {
      type: 'nodeEdgeList',
      data: {
        nodes: this._nodeParser(this.data),
        edges: this._edgeParser(this.data),
        combos: [],
      },
    };
  }

  _nodeParser(data) {
    const actorList = data.map((item) => item.actor);
    const objectList = data.map((item) => item.object);
    const nodeRawList = [...actorList, ...objectList];
    const nodeList = [...new Set(nodeRawList)];
    const nodeListFilter = nodeList.filter((item) => item !== ''); // To check whether data is empty
    return nodeListFilter.map((item) => nodePlate(item, item));
  }

  _edgeParser(data) {
    // Filtering the data that has empty actor and object
    const rawData = data.filter((item) => {
      if (item.actor !== '' && item.object !== '') {
        return true;
      }
      return false;
    });

    const edgeList = rawData.map((item) => {
      if (item.verb.startsWith('>')) {
        return {
          source: item.actor,
          target: item.object,
          label: item.verb.slice(1),
          style: {
            endArrow: true,
          },
        };
      }
      return {
        source: item.actor,
        target: item.object,
        label: item.verb,
      };
    });
    return edgeList;
  }

  /**
   * Combo Prop Parsing Function and helper functions
   */

  comboPropParsing() {
    const propData = this._sanitizeComboData();
    // Push Combos inside Graph
    const combosData = this._comboPush(propData);

    const updatedNodeData = this._updatedNodeList(propData);

    // Create or update combo
    // const propData = this._updatedNodeList(propData);

    // Fetch List of Nodes
    const fetchData = this._fetchList(propData);
    /**
     * 1. Sanitize Json and remove nodeid with empty string
     * 2. Loop over sanitized Json
     * 3. And check if node exist in graph then update and if not then create with nodeplate()
     * 4. But in both case you have to update nodeModel
     * 5. Check if prop value is an empty string then skip it else put it inside props: { section }
     * 6. dont populate fetch and combo.
     * 7. And For combo update this as comboId inside nodeModel.update
     * 8. Now filterout list of fetch and combo
     * 9. Send list of nodes and combos with empty edges and fetch list.
     * 10. { type:'comboProp' graphList: { nodes: [], combos: [], fetch: [] }, fetch: [] }
     */
    return {
      type: 'comboPropList',
      data: {
        combosList: combosData,
        nodeList: updatedNodeData,
        fetchList: fetchData,
      },
    };
  }

  _sanitizeComboData() {
    return this.data.filter((item) => {
      if (item.nodeid !== '') {
        return true;
      }
      return false;
    });
  }

  _updatedNodeList(propData) {
    const comboChildrens = [];

    propData.forEach((item) => {
      const nodeId = item.nodeid;

      const nodeBoiler = nodePlate(nodeId, nodeId);

      // To Extract properteis - Non reserved headers
      const propFetch = Object.keys(item).reduce((acc, curr) => {
        if (this.reserveHeads.indexOf(curr.toLowerCase()) === -1) {
          if (item[curr] !== '') {
            // If value is empty
            acc[curr] = {
              type: 'custom',
              value: item[curr],
            };
          }
        }
        return acc;
      }, {});

      // Prop Fix data
      if (Object.keys(propFetch).length > 0) {
        nodeBoiler.props = propFetch;
      }

      // Handling of reserved Heads
      if (item.combo) {
        if (item.combo !== '') {
          nodeBoiler.comboId = item.combo;
        }
      }

      if (item.description) {
        if (item.description !== '') {
          nodeBoiler.description.content = item.description;
        }
      }

      if (item.color) {
        if (item.color !== '') {
          nodeBoiler.style.fill = item.color;
        }
      }

      comboChildrens.push(nodeBoiler);
    });

    return comboChildrens;
  }

  _comboPush(comboPropData) {
    if (Object.hasOwn(comboPropData[0], 'combo')) {
      const rawComboList = comboPropData.map((item) => {
        return item.combo;
      });
      const comboList = [...new Set(rawComboList)];
      const finalComboList = comboList.filter((item) => {
        return item !== '';
      });
      return finalComboList;
    }
    return []; // Check if somethings weird
  }

  _fetchList(comboPropData) {
    if (Object.hasOwn(comboPropData[0], 'fetch')) {
      const rawFetchList = comboPropData.filter((item) => {
        return item.fetch.toLowerCase() === 'yes';
      });

      const fetchList = rawFetchList.map((item) => {
        return item.nodeid;
      });

      return fetchList;
    }
    return []; // Check if somethings weird
  }

  /**
   * Data format is invalid
   */

  invalidData() {
    // trow error

    return new Error('Invalid data, please enter correct csv');
  }
}

export default GraphParse;
