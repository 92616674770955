import startToEnd from '@/utils/startToEnd';

export default function findNodes(payloadStr) {
  const stringObj = {
    str: payloadStr,
    start: 'include(',
    end: ')',
  };
  const sanitizedWord = startToEnd(stringObj);

  return sanitizedWord[0].split(',');
  // an additional loop if you want to separate or write multiple query which i dont think necessary in this case
}
