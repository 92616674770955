// Function to generate a random UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// function getSerializedJsonString(obj) {
//   // eslint-disable-next-line no-shadow
//   function serializeToJson(obj) {
//     return JSON.stringify(obj).replace(/"/g, '\\"');
//   }

//   const serializedData = serializeToJson(obj);
//   return `"${serializedData}"`;
// }

function getRandomNumber(start, end) {
  if (start >= end) {
    throw new Error("The 'start' parameter must be less than the 'end' parameter.");
  }

  // Calculate a random number between start (inclusive) and end (exclusive).
  const randomNumber = Math.random() * (end - start) + start;

  return randomNumber;
}

function mapExploreGraphToNodaVr(inputJson, scale = 1) {
  console.log('xvf', 'explore', typeof scale);
  const outputObj = {
    name: `Untitled ${new Date().toISOString()}`,
    format: 'v2',
    metaNodes: [],
    metaLinks: [],
    nodes: [],
    links: [],
  };

  const nodeMap = new Map();

  inputJson.nodes.forEach((node) => {
    let note; let
      description;
    if (node.description) {
      if (typeof node.description === 'object') {
        console.log(node.description);
        note = `${node.description.content.substring(0, 30)}...`;
        description = node.description.content;
      } else {
        note = `${node.description.substring(0, 30)}...`;
        description = node.description;
      }
    } else {
      note = node.label;
      description = 'This Node Doesn\'t seems to have description yet.';
    }
    const newNode = {
      kind: null,
      position: {
        x: getRandomNumber(-0.67 * scale, 0.67 * scale),
        y: getRandomNumber(-0.5 * scale, 0.5 * scale),
        z: getRandomNumber(-0.83 * scale, 0.83 * scale),
      },
      facing: {
        w: -0.277152419,
        x: 0,
        y: 0.960826,
        z: 0,
      },
      relativeTo: 'User',
      collapsed: true,
      folded: false,
      opacity: 1,
      uuid: generateUUID(),
      tone: {
        r: Math.random(),
        g: Math.random(),
        b: Math.random(),
        a: 1,
      },
      size: 5,
      shape: 'Ball',
      properties: [
        {
          uuid: generateUUID(),
          name: 'Name',
          text: note,
          image: null,
          video: null,
          tone: {
            r: Math.random(),
            g: Math.random(),
            b: Math.random(),
            a: 1,
          },
          size: 1,
          page: null,
          notes: description,
        },
      ],
    };
    outputObj.nodes.push(newNode);
    nodeMap.set(node.label, newNode.uuid);
  });

  inputJson.edges.forEach((edge) => {
    const fromNodeId = nodeMap.get(edge.source);
    const toNodeId = nodeMap.get(edge.target);
    //   console.log(fromNodeId, toNodeId);
    if (fromNodeId !== undefined && toNodeId !== undefined) {
      const newEdge = {
        title: edge.label,
        kind: null,
        fromNode: { id: 0, Uuid: fromNodeId },
        toNode: { id: 0, Uuid: toNodeId },
        folded: false,
        uuid: generateUUID(),
        tone: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
        curve: 'CUp',
        trail: 'Cone',
        size: 3,
        shape: 'Arrows',
        properties: null,
      };
      outputObj.links.push(newEdge);
    }
  });
  // console.log('xvf', outputObj);
  // const jsonString = getSerializedJsonString(outputObj);
  const jsonString = `${JSON.stringify(outputObj)}`;
  return jsonString;
}

// Rest of the code remains the same as provided in the previous response

export default mapExploreGraphToNodaVr;
