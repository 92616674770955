<template>
  <div>
    <!-- layout type -->
    <div class="">
      <label class="form-label ed-label">Choose Layout Type:</label>
      <div class="mb-3">
        <ed-dropdown
          :selected="layoutTypeData"
          :listItems="availableLayouts"
          :isSelected="setAvailableLayouts"
        />
      </div>
    </div>

    <div class="">
      <label class="form-label ed-label">Choose Edge Type:</label>
      <div class="mb-3">
        <ed-dropdown
          :selected="edgeTypeData"
          :listItems="availableEdgeTypes"
          :isSelected="setEdgeType"
        />
      </div>
    </div>
    <!-- edge strength -->
    <div class="" v-if="isConfigVisible('edgeStrength')">
      <label for="nodeSize" class="form-label ed-label">Relax Nodes</label>
      : {{ edgeStrength }}
      <input
        type="range"
        min="0"
        max="200"
        class="form-range ed-slider"
        v-model="edgeStrength"
        id="nodeSize"
      />
    </div>
    <!-- link distance -->
    <div class="" v-if="isConfigVisible('linkDistance')">
      <label for="linkDistance" class="form-label ed-label"
        >Link Distance</label
      >
      : {{ linkDistance }}
      <input
        type="range"
        min="0"
        max="400"
        v-model="linkDistance"
        class="form-range ed-slider"
        id="linkDistance"
      />
    </div>
    <!-- node strength -->
    <div class="" v-if="isConfigVisible('nodeStrength')">
      <label for="nodeStrength" class="form-label ed-label"
        >Node Strength</label
      >
      : {{ nodeStrength }}
      <input
        type="range"
        min="0"
        max="600"
        v-model="nodeStrength"
        class="form-range ed-slider"
        id="nodeStrength"
      />
    </div>
    <!-- prevent Overlap -->
    <div class="" v-if="isConfigVisible('preventOverlap')">
      <label for="preventOverlap" class="form-label ed-label">
        Prevent Overlap
      </label>
      : {{ preventOverlap }}
      <div class="form-check form-switch">
        <input
          role="button"
          class="form-check-input"
          type="checkbox"
          v-model="preventOverlap"
          id="flexSwitchCheckChecked"
          checked
        />
      </div>
    </div>
    <!-- nodesep -->
    <div class="" v-if="isConfigVisible('nodesep')">
      <label for="nodesep" class="form-label ed-label"> Node Separation </label>
      : {{ nodesep }}
      <input
        type="range"
        min="0"
        max="100"
        v-model="nodesep"
        class="form-range ed-slider"
        id="nodesep"
      />
    </div>
    <!-- ranksep -->
    <div class="" v-if="isConfigVisible('ranksep')">
      <label for="ranksep" class="form-label ed-label"> Rank Separation </label>
      : {{ ranksep }}
      <input
        type="range"
        min="0"
        max="400"
        v-model="ranksep"
        class="form-range ed-slider"
        id="ranksep"
      />
    </div>
    <!-- rankdir -->
    <div class="" v-if="isConfigVisible('rankdir')">
      <label for="rankdir" class="form-label ed-label"> Rank Direction </label>
      : {{ rankdir }}
      <div class="mb-3">
        <ed-dropdown
          :selected="rankdir"
          :listItems="rankdirList"
          :isSelected="setRankDir"
        />
      </div>
    </div>
    <!-- align -->
    <div class="" v-if="isConfigVisible('align')">
      <label for="align" class="form-label ed-label"> Alignment </label>
      : {{ align }}
      <div class="mb-3">
        <ed-dropdown
          :selected="align"
          :listItems="alignList"
          :isSelected="setAlign"
        />
      </div>
    </div>
    <!-- maxIteration -->
    <div class="" v-if="isConfigVisible('maxIteration')">
      <label for="maxIteration" class="form-label ed-label">
        Max Iteration
      </label>
      : {{ maxIteration }}
      <input
        type="range"
        min="0"
        max="100"
        v-model="maxIteration"
        class="form-range ed-slider"
        id="maxIteration"
      />
    </div>
    <!-- unit Radius -->
    <div class="" v-if="isConfigVisible('unitRadius')">
      <label for="unitRadius" class="form-label ed-label"> Unit Radius </label>
      : {{ unitRadius }}
      <input
        type="range"
        min="50"
        max="400"
        v-model="unitRadius"
        class="form-range ed-slider"
        id="unitRadius"
      />
    </div>
    <!-- focus Node -->
    <div class="" v-if="isConfigVisible('focusNode')">
      <label for="focusNode" class="form-label ed-label"> Focus Node </label>
      : {{ focusNode }}
      <div class="mb-3">
        <ed-dropdown
          :selected="focusNode"
          :listItems="nodeList"
          :isSelected="setFocusNode"
        />
      </div>
    </div>
    <!-- node Size -->
    <div class="" v-if="isConfigVisible('nodeSize')">
      <label for="nodeSize" class="form-label ed-label"> Node Size </label>
      : {{ nodeSize }}
      <input
        type="range"
        min="0"
        max="500"
        v-model="nodeSize"
        class="form-range ed-slider"
        id="nodeSize"
      />
    </div>
    <!-- start Radius -->
    <div class="" v-if="isConfigVisible('startRadius')">
      <label for="startRadius" class="form-label ed-label">
        Start Radius
      </label>
      : {{ startRadius }}
      <input
        type="range"
        min="0"
        max="800"
        v-model="startRadius"
        class="form-range ed-slider"
        id="startRadius"
      />
    </div>
    <!-- end Radius -->
    <div class="" v-if="isConfigVisible('endRadius')">
      <label for="endRadius" class="form-label ed-label"> End Radius </label>
      : {{ endRadius }}
      <input
        type="range"
        min="0"
        max="800"
        v-model="endRadius"
        class="form-range ed-slider"
        id="endRadius"
      />
    </div>
    <!-- clockwise -->
    <div class="" v-if="isConfigVisible('clockwise')">
      <label for="clockwise" class="form-label ed-label"> Clockwise </label>
      : {{ clockwise }}
      <div class="form-check form-switch">
        <input
          role="button"
          class="form-check-input"
          type="checkbox"
          v-model="clockwise"
          id="flexSwitchCheckChecked"
          checked
        />
      </div>
    </div>
    <!-- gravity -->
    <div class="" v-if="isConfigVisible('gravity')">
      <label for="gravity" class="form-label ed-label">Gravity</label>
      : {{ gravity }}
      <input
        type="range"
        min="0"
        max="100"
        v-model="gravity"
        class="form-range ed-slider"
        id="gravity"
      />
    </div>
    <!-- speed -->
    <div class="" v-if="isConfigVisible('speed')">
      <label for="speed" class="form-label ed-label">Speed</label>
      : {{ speed }}
      <input
        type="range"
        min="0"
        max="300"
        v-model="speed"
        class="form-range ed-slider"
        id="speed"
      />
    </div>
    <!-- clusterGravity -->
    <div class="" v-if="isConfigVisible('clusterGravity')">
      <label for="clusterGravity" class="form-label ed-label"
        >Cluster Gravity</label
      >
      : {{ clusterGravity }}
      <input
        type="range"
        min="0"
        max="300"
        v-model="clusterGravity"
        class="form-range ed-slider"
        id="clusterGravity"
      />
    </div>
    <!-- rows -->
    <div class="" v-if="isConfigVisible('rows')">
      <label for="row" class="form-label ed-slider">Rows</label>
      : {{ rows }}
      <input
        type="range"
        min="0"
        max="10"
        v-model="rows"
        class="form-range ed-slider"
        id="row"
      />
    </div>
    <!-- columns -->
    <div class="" v-if="isConfigVisible('cols')">
      <label for="column" class="form-label ed-label"> Columns </label>
      : {{ cols }}
      <input
        type="range"
        min="0"
        max="10"
        v-model="cols"
        class="form-range ed-slider"
        id="column"
      />
    </div>
  </div>
</template>

<script>
import graphConfig from '@/config/graphConfig';
import graphReactor from '@/common/core/Graph/graphReactor';
import edDropdown from '@/common/components/ed-forms/ed-dropdown.vue';

export default {
  components: {
    edDropdown,
  },

  created() {
    setTimeout(() => {
      this.$graphLayoutConfig.loadGraphConfigs(graphReactor.exploreInstance);
    }, 100);
  },

  mounted() {
    if (this.layoutTypeData === 'GForce') {
      this.edgeStrength = this.elementFromStore('edgeStrength');
      this.nodeStrength = this.elementFromStore('nodeStrength');
      this.linkDistance = this.elementFromStore('linkDistance');
      this.preventOverlap = this.elementFromStore('preventOverlap');
    }

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (
        mutation.type === 'SET_GRAPH_PROPERTIES'
        && mutation.payload.property === 'layoutType'
      ) {
        const layout = this.$store.state.graphConfigs.layoutType;
        if (layout === 'force') {
          this.linkDistance = this.elementFromStore('linkDistance');
          this.nodeStrength = this.elementFromStore('nodeStrength');
          this.edgeStrength = this.elementFromStore('edgeStrength');
          this.nodeSize = this.elementFromStore('nodeSize');
          this.preventOverlap = this.elementFromStore('preventOverlap');
        } else if (layout === 'gForce') {
          this.edgeStrength = this.elementFromStore('edgeStrength');
          this.nodeStrength = this.elementFromStore('nodeStrength');
          this.linkDistance = this.elementFromStore('linkDistance');
          this.preventOverlap = this.elementFromStore('preventOverlap');
        } else if (layout === 'dagre') {
          this.nodesep = this.elementFromStore('nodesep');
          this.ranksep = this.elementFromStore('ranksep');
          this.rankdir = this.elementFromStore('rankdir');
          this.align = this.elementFromStore('align');
        } else if (layout === 'concentric') {
          this.linkDistance = this.elementFromStore('linkDistance');
          this.equidistance = this.elementFromStore('equidistance');
          this.nodeSize = this.elementFromStore('nodeSize');
          this.sweep = this.elementFromStore('sweep');
        } else if (layout === 'radial') {
          this.linkDistance = this.elementFromStore('linkDistance');
          this.maxIteration = this.elementFromStore('maxIteration');
          this.unitRadius = this.elementFromStore('unitRadius');
          this.focusNode = this.elementFromStore('focusNode');
          this.preventOverlap = this.elementFromStore('preventOverlap');
          this.nodeSize = this.elementFromStore('nodeSize');
        } else if (layout === 'circular') {
          this.startRadius = this.elementFromStore('startRadius');
          this.endRadius = this.elementFromStore('endRadius');
          this.clockwise = this.elementFromStore('clockwise');
          this.preventOverlap = this.elementFromStore('preventOverlap');
        } else if (layout === 'fruchterman') {
          this.gravity = this.elementFromStore('gravity');
          this.speed = this.elementFromStore('speed');
          this.clusterGravity = this.elementFromStore('clusterGravity');
          this.maxIteration = this.elementFromStore('maxIteration');
          this.preventOverlap = this.elementFromStore('preventOverlap');
        } else if (layout === 'mds') {
          this.linkDistance = this.elementFromStore('linkDistance');
          this.preventOverlap = this.elementFromStore('preventOverlap');
        } else if (layout === 'grid') {
          this.rows = this.elementFromStore('rows');
          this.cols = this.elementFromStore('cols');
          this.nodeSize = this.elementFromStore('nodeSize');
          this.preventOverlap = this.elementFromStore('preventOverlap');
        }
      }
    });
  },

  data() {
    return {
      edgeTypeData: 'cubic-vertical',
      edgeStrength: null,
      nodeStrength: null,
      linkDistance: null,
      preventOverlap: null,
      nodesep: null,
      ranksep: null,
      rankdir: null,
      align: null,
      maxIteration: null,
      unitRadius: null,
      focusNode: null,
      nodeSize: null,
      sweep: null,
      startRadius: null,
      endRadius: null,
      clockwise: null,
      gravity: null,
      speed: null,
      clusterGravity: null,
      rows: null,
      cols: null,
      rankdirList: ['TB', 'BT', 'LR', 'RL'],
      alignList: ['UL', 'UR', 'DL', 'DR'],
      rangeData: 0,
      availableLayouts: [
        'force',
        'dagre',
        'radial',
        'circular',
        'gForce',
        'fruchterman',
        'concentric',
        'mds',
        'grid',
        'predictLayout',
      ],
      availableEdgeTypes: [
        'line',
        'polyline',
        'arc',
        'quadratic',
        'cubic',
        'cubic-vertical',
        'cubic-horizontal',
        'loop',
      ],
      visibilityMetrix: {
        gforce: {
          edgeStrength: true,
          nodeStrength: true,
          linkDistance: true,
          preventOverlap: true,
        },
        force: {
          edgeStrength: true,
          nodeStrength: true,
          linkDistance: true,
          preventOverlap: true,
          nodeSize: true,
        },
        dagre: {
          nodesep: true,
          ranksep: true,
          rankdir: true,
          align: true,
        },
        concentric: {
          minNodeSpacing: true,
          equidistance: true,
          nodeSize: true,
          sweep: true,
        },
        radial: {
          linkDistance: true,
          maxIteration: true,
          unitRadius: true,
          focusNode: true,
          preventOverlap: true,
          nodeSize: true,
        },
        circular: {
          startRadius: true,
          endRadius: true,
          clockwise: true,
          preventOverlap: true,
        },
        fruchterman: {
          gravity: true,
          speed: true,
          clusterGravity: true,
          maxIteration: true,
          preventOverlap: true,
        },
        mds: {
          linkDistance: true,
          preventOverlap: true,
        },
        grid: {
          rows: true,
          cols: true,
          nodeSize: true,
          preventOverlap: true,
        },
      },
    };
  },

  computed: {
    layoutTypeData() {
      return (
        this.$store.state.graphConfigs.layoutType || graphConfig.layout.type
      );
    },
    getCurrentRange() {
      return this.$store.state.explore_data_range;
    },
    nodeList() {
      return this.$store.getters['gmodule/getDimensionArray'];
    },
  },

  watch: {
    rangeData(val) {
      this.$store.dispatch('setRangeValue', val);
    },
    edgeStrength(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'edgeStrength',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    linkDistance(oldVal, newVal) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'linkDistance',
        layout: this.layoutTypeData,
        value: newVal,
      });
    },
    nodeStrength(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'nodeStrength',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    preventOverlap(val) {
      // console.log('graphConfig: preventOverlap');
      this.$store.dispatch('updateGraphConfig', {
        property: 'preventOverlap',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    nodesep(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'nodesep',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    ranksep(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'ranksep',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    maxIteration(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'maxIteration',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    unitRadius(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'unitRadius',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    focusNode(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'focusNode',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    nodeSize(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'nodeSize',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    sweep(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'sweep',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    startRadius(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'startRadius',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    endRadius(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'endRadius',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    clockwise(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'clockwise',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    gravity(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'gravity',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    speed(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'speed',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    clusterGravity(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'clusterGravity',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    rows(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'rows',
        layout: this.layoutTypeData,
        value: val,
      });
    },
    cols(val) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'cols',
        layout: this.layoutTypeData,
        value: val,
      });
    },
  },
  methods: {
    setAvailableLayouts(item) {
      this.$store.dispatch('updateGraphConfig', {
        property: 'layoutType',
        layout: this.layoutTypeData,
        value: item,
      });
    },
    setEdgeType(edgeType) {
      this.edgeTypeData = edgeType;
      graphReactor.exploreInstance.getEdges().forEach((edge) => {
        edge.update({
          type: edgeType,
          labelCfg: {
            position: 'top',
            refY: -10,
            color: '#fff',
            style: {
              fontSize: 15,
              fill: '#fff',
              fontWeight: 500,
              fontFamily: 'Poppins',
              color: '#fff',
            },
          },
        });
      });
    },
    isConfigVisible(configName) {
      if (
        this.visibilityMetrix[this.layoutTypeData.toLowerCase()][configName]
      ) {
        return true;
      }
      return false;
    },
    setRankDir(item) {
      this.rankdir = item;
      this.$store.dispatch('updateGraphConfig', {
        property: 'rankdir',
        layout: this.layoutTypeData,
        value: item,
      });
    },
    setAlign(item) {
      this.align = item;
      this.$store.dispatch('updateGraphConfig', {
        property: 'align',
        layout: this.layoutTypeData,
        value: item,
      });
    },
    setFocusNode(item) {
      this.focusNode = item;
      this.$store.dispatch('updateGraphConfig', {
        property: 'focusNode',
        layout: this.layoutTypeData,
        value: item,
      });
    },
    elementFromStore(element) {
      return this.$store.state.graphConfigs[this.layoutTypeData.toLowerCase()][
        element
      ];
    },
    setNodeSize(e) {
      console.log('graphConfig', e);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/abstracts/variables";
@import "../../../assets/scss/components/ed-slider";
</style>
