// eslint-disable-next-line
import findNodes from '@/common/core/Graph/controller_modes/findNodes';
/* eslint-disable brace-style */
function filterQuery(str) {
  const onelineStr = str.replace(/(\r\n|\n|\r)/gm, ' ');
  return onelineStr
    .split('$ed ')
    .filter((item) => item.includes(' ed$'))
    .map((item) => item.split(' ed$')[0].trim());
}

// const query1 = `Please add this query  $ed  Gamer | has relationship with | Naruto     ed$ asds
// $ed  Gamer | Game | Bro     ed$`;
// pass your v-model inside the function

// const result = filterQuery(query1);
function uniqueIdGenerate() {
  // Generate random id for node
  return Math.random().toString(36).substr(2, 9);
}

function middleConversion(queryObj) {
  const newList = {};
  const { edQueries } = queryObj;
  if (queryObj.type === 'normal') {
    const sanitizedQuery = edQueries.split(',');
    sanitizedQuery.forEach((subItem) => {
      const uniqueId = uniqueIdGenerate();
      const node1Key = subItem.split(' | ')[0].trim();
      const node2Key = subItem.split(' | ')[2].trim();
      const edgeKey = subItem.split(' | ')[1].trim();
      const nodes = [
        {
          id: node1Key,
          label: node1Key,
        },
        {
          id: node2Key,
          label: node2Key,
        },
      ];
      const edges = [
        {
          source: node1Key,
          target: node2Key,
          label: edgeKey,
        },
      ];
      const combos = [];
      newList[uniqueId] = {
        nodes,
        edges,
        combos,
      };
    });
  } else if (queryObj.type === 'smart') {
    // /(\||\-|\,)/g
    const mainQuery = edQueries.split('.');

    const nodes = [];
    const edges = [];
    mainQuery.forEach((edQuery) => {
      const sanitizedQuery = edQuery.split(/\|/g);
      sanitizedQuery.forEach((subItem, subIndex) => {
        if ((subIndex + 1) % 2 === 0) {
          const relationshipLabel = subItem.trim();

          // If both source and target contains ,
          if (sanitizedQuery[subIndex - 1].includes(',') && sanitizedQuery[subIndex + 1].includes(',')) {
            const sourceNodes = sanitizedQuery[subIndex - 1].split(',');
            const targetNodes = sanitizedQuery[subIndex + 1].split(',');
            targetNodes.forEach((targetNode) => {
              nodes.push({
                id: targetNode.trim(),
                label: targetNode.trim(),
              });
            });
            sourceNodes.forEach((sourceNode) => {
              nodes.push({
                id: sourceNode.trim(),
                label: sourceNode.trim(),
              });
              targetNodes.forEach((targetNode) => {
                if (relationshipLabel.includes('>')) {
                  edges.push({
                    source: sourceNode.trim(),
                    target: targetNode.trim(),
                    label: relationshipLabel.replace('>', ''),
                    style: {
                      endArrow: true,
                    },
                  });
                } else {
                  edges.push({
                    source: sourceNode.trim(),
                    target: targetNode.trim(),
                    label: relationshipLabel,
                  });
                }
              });
            });
          }
          // if only source contains ,
          else if (sanitizedQuery[subIndex - 1].includes(',') && !sanitizedQuery[subIndex + 1].includes(',')) {
            const sourceNodes = sanitizedQuery[subIndex - 1].split(',');
            sourceNodes.forEach((sourceNode) => {
              nodes.push({
                id: sourceNode.trim(),
                label: sourceNode.trim(),
              });
              nodes.push({
                id: sanitizedQuery[subIndex + 1].trim(),
                label: sanitizedQuery[subIndex + 1].trim(),
              });
              if (relationshipLabel.includes('>')) {
                edges.push({
                  source: sourceNode.trim(),
                  target: sanitizedQuery[subIndex + 1].trim(),
                  label: relationshipLabel.replace('>', ''),
                  style: {
                    endArrow: true,
                  },
                });
              } else {
                edges.push({
                  source: sourceNode.trim(),
                  target: sanitizedQuery[subIndex + 1].trim(),
                  label: relationshipLabel,
                });
              }
            });
          }
          // if only target contains ,
          else if (!sanitizedQuery[subIndex - 1].includes(',') && sanitizedQuery[subIndex + 1].includes(',')) {
            const targetNodes = sanitizedQuery[subIndex + 1].split(',');
            targetNodes.forEach((targetNode) => {
              nodes.push({
                id: targetNode.trim(),
                label: targetNode.trim(),
              });
              nodes.push({
                id: sanitizedQuery[subIndex - 1].trim(),
                label: sanitizedQuery[subIndex - 1].trim(),
              });
              if (relationshipLabel.includes('>')) {
                edges.push({
                  source: sanitizedQuery[subIndex - 1].trim(),
                  target: targetNode.trim(),
                  label: relationshipLabel.replace('>', ''),
                  style: {
                    endArrow: true,
                  },
                });
              } else {
                edges.push({
                  source: sanitizedQuery[subIndex - 1].trim(),
                  target: targetNode.trim(),
                  label: relationshipLabel,
                });
              }
            });
            // eslint-disable-next-line brace-style
          }
          // if neither contains ,
          else {
            nodes.push({
              id: sanitizedQuery[subIndex - 1].trim(),
              label: sanitizedQuery[subIndex - 1].trim(),
            });
            nodes.push({
              id: sanitizedQuery[subIndex + 1].trim(),
              label: sanitizedQuery[subIndex + 1].trim(),
            });
            if (relationshipLabel.includes('>')) {
              edges.push({
                source: sanitizedQuery[subIndex - 1].trim(),
                target: sanitizedQuery[subIndex + 1].trim(),
                label: relationshipLabel.replace('>', ''),
                style: {
                  endArrow: true,
                },
              });
            } else {
              edges.push({
                source: sanitizedQuery[subIndex - 1].trim(),
                target: sanitizedQuery[subIndex + 1].trim(),
                label: relationshipLabel,
              });
            }
          }
          // eslint-disable-next-line no-const-assign
          newList[uniqueIdGenerate()] = {
            nodes,
            edges,
          };
        }
      });
    });
  } else if (queryObj.type === 'remove') {
    newList[uniqueIdGenerate()] = findNodes(edQueries);
  } else if (queryObj.type === 'filter') {
    newList[uniqueIdGenerate()] = findNodes(edQueries);
  }
  return newList;
}

function filterResult(queryObj) {
  const resultPayload = queryObj.query.reduce((newList, item) => {
    const arrayOfEdQueries = item;
    const payload = {
      type: queryObj.type,
      edQueries: arrayOfEdQueries,
    };
    // eslint-disable-next-line
    newList = middleConversion(payload);
    return newList;
  }, {});
  return resultPayload;
}

export default {
  filterQuery,
  filterResult,
};
